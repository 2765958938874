<template>
  <div class="accountWrap">
    <!-- 表单 -->
    <div class="facilityBox">
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="司机手机" prop="DriverPhone" label-width="80px">
          <el-input
            class="fromInp"
            v-model="manageForm.DriverPhone"
            placeholder="输入司机手机"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search('manageForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm('manageForm')" icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-row class="settleBox">
        <el-col :span="4">运单数量(条)：<strong>{{ invoices.InvoiceNumber }}</strong></el-col>
        <el-col :span="4">发票数量(张)：<strong>{{ invoices.countInfo }}</strong></el-col>
        <!-- <el-col :span="5">历史合作货主数(位)：<strong>{{ invoices.ApplicantCount }}</strong></el-col> -->
        <el-col :span="5">司机数量(位)：<strong>{{ invoices.DriverCount }}</strong></el-col>
        <el-col :span="6">运费(元)：<strong>{{ invoices.CarriageTotalPrice }}</strong></el-col>
      </el-row>
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="loading">
        <el-table-column
          :index="indexMethod"
          type="index"
          align="center"
          label="序号"
          width="50"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop=""
          label="司机虚拟账户"
         show-overflow-tooltip></el-table-column>
        <el-table-column
          align="center"
          prop="DriverName"
          label="司机姓名"
         show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="DriverIDCard"
          label="司机身份证号"
         show-overflow-tooltip></el-table-column> -->
        <el-table-column
          align="center"
          prop="DriverPhone"
          label="司机手机号码"
         show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="BankerAccount"
          label="收款人账号"
         show-overflow-tooltip></el-table-column> -->
        <el-table-column
          align="center"
          prop="CarriageTotalPrice"
          label="运单累计分账金额"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              ￥{{ scope.row.CarriageTotalPrice }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="备注"
         show-overflow-tooltip></el-table-column>
        <!-- <el-table-column align="center" label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row)"
              icon="el-icon-tickets"
              >查看详情</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { invoiceInfoList, ByDriver } from "@/api/finance/account/index";
export default {
  data() {
    return {
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 服务商-司机历史结算汇总
      invoices: "",
      // 搜索参数
      manageForm: {
        DriverPhone: "",
      },
      loading:true
    };
  },
  created() {
    // 获取结算信息列表
    this.tasksInfoList();
    // 汇总开票相关信息
    this.taskSummary();
  },
  methods: {
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.pagination.page = 1;
      this.manageForm.DriverPhone = "";
      this.tasksInfoList();
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.page = 1;
      this.tasksInfoList();
    },
    // 汇总开票相关信息
    taskSummary() {
      ByDriver().then((res) => {
        this.invoices = res.invoices[0];
      });
    },
    // 获取结算信息列表
    tasksInfoList() {
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        DriverPhone: this.manageForm.DriverPhone,
        RoleType: 1,
      };
      invoiceInfoList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: e,
        DriverPhone: this.manageForm.DriverPhone,
      };
      invoiceInfoList({ Json: JSON.stringify(params) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
      }).finally(() => {
        this.loading = false;
      })
    },
    // 序号
    indexMethod(index) {
      return (this.pagination.page - 1) * this.pagination.pagesize + index + 1;
    },
    //查看结算单详情
    goFacility(item) {
      this.$router.push({
        path: "/closeAccount/driverClose/driverInfo",
        query: {
          PrincipalCompany: item.PrincipalCompany,
          DriverPhone: item.DriverPhone,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.accountWrap {
  .accountTitle {
    margin: 20px 0 0 0;
    font-size: 24px;
    padding: 0 32%;
    text-align: center;
    .accountInfo {
      width: 30vw;
      background: #f2f2f2;
      padding: 10px;
      border-radius: 10px;
      margin: 20px 0 0 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}
</style>